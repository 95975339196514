
/**
 * @name: 商城管理-公告管理
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-公告管理
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  noticePageApi,
  noticeDetailAPi,
  noticeCreateApi,
  noticeModifyApi,
  noticeDelApi,
  noticeStatusApi
} from '@/apis/mall/notice'
import {
  INotice,
  INoticeParams
} from '@/apis/mall/notice/types'
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy } from "@/utils/common";

@Component({})
export default class mallNotice extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: INotice[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: INoticeParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<INotice> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    viewTitle: '详情',
    addTitle: '新增公告',
    editTitle: '编辑公告',
    column: [
      {
        label: '公告ID',
        prop: 'id',
        width: 180,
        align: 'left',
        addHide: true,
        editHide: true,
        viewHide: true,
        slot: true
      },
      {
        label: '公告标题',
        prop: 'noticeTitle',
        span: 24,
        overHidden: true,
        align: "center",
        search: true,
        maxlength: 50,
        rules: [
          {
            required: true,
            message: '请输入公告标题',
            trigger: 'blur'
          }
        ]
      },
      {
        label: '经办人',
        prop: 'realName',
        search: true,
        width: 150,
        overHidden: true,
        addHide: true,
        editHide: true,
        viewHide: true,
        align: 'center'
      },
      {
        label: '创建时间',
        prop: 'addTime',
        width: 180,
        type: 'daterange',
        search: true,
        addHide: true,
        editHide: true,
        viewHide: true,
        align: 'center'
      },
      {
        label: '首页展示',
        prop: 'status',
        type: 'select',
        search: true,
        dicData: [
          {
            label: '展示',
            value: 1
          },
          {
            label: '不展示',
            value: 2
          }
        ],
        editHide: true,
        addHide: true,
        viewHide: true,
        align: 'center',
        slot: true
      },
      {
        label: '公告详情',
        prop: 'noticeDtl',
        hide: true,
        editSlot: true,
        addSlot: true,
        viewSlot: true
      }
    ]
  }

  getList () {
    this.tableLoading = true

    const query: INoticeParams = deepCopy(this.queryParam)
    if (query.addTime && query.addTime.length) {
      query.addTimeStart = query.addTime[0]
      query.addTimeEnd = query.addTime[1]
    } else {
      query.addTimeStart = ''
      query.addTimeEnd = ''
    }
    delete query.addTime

    noticePageApi(query).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  openView (row: INotice, index: number) {
    noticeDetailAPi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowView(e, index)
    })
  }


  openEdit (row: INotice, index: number) {
    noticeDetailAPi(row.id).then(e => {
      // @ts-ignore
      this.$refs.crudRef.rowEdit(e, index)
    })
  }


  rowSave (form: INotice, done: Function, loading: Function) {
    noticeCreateApi(form).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: INotice, done: Function, loading: Function) {
    noticeModifyApi(form).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
        done()
      }
    }).finally(() => {
      loading()
    })
  }

  rowDel (form: INotice, index: number) {
    noticeDelApi(form.id).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  switchStatus (id: string, status: number) {
    noticeStatusApi(id).then(e => {
      if (e) {
        this.getList()
        this.$message.success('操作成功!')
      }
    })
  }

  created () {
    this.getList()
  }
}
