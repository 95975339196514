/**
 * @name: 商城管理-公告管理接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 商城管理-公告管理接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { INotice, INoticeParams } from "./types";

export const noticePageApi = (params: INoticeParams) => get<IPageRes<INotice[]>>("/admin/notice/query", params)

export const noticeDetailAPi = (id: string) => get<INotice>("/admin/notice/detail/" + id)

export const noticeCreateApi = (data: Partial<INotice>) => postJ("/admin/notice/create", data)

export const noticeModifyApi = (data: Partial<INotice>) => postJ("/admin/notice/modify", data)

export const noticeDelApi = (id: string) => get("/admin/notice/remove/" + id)

export const noticeStatusApi = (id: string) => postJ('/admin/notice/modifyStatus', {id})
